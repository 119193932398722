@import '~react-image-gallery/styles/css/image-gallery.css';

:root {
  --primary-color: #d79225 !important;
  --shadow-color: #e3c390;
  --secondry-color: #110d0e;
  --bc-color: #fff;
  --secondry-bc-color: #000;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url('./assets/images/background.png');
  position: relative;
}
html {
  font-family: 'Roboto', sans-serif;
}

span.p-dialog-header-close-icon.pi.pi-times {
  color: var(--primary-color);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus{
  box-shadow: 0 0 0 0.1rem #ffa50040;
}

/* table styles */
.p-dialog-right.p-dialog {
  margin: 0;
  max-height: auto;
}
.p-dialog .p-dialog-header {
  padding: 1rem !important;
}
.fc .fc-toolbar{
  justify-content: right;
}
.fc .fc-toolbar-title {
  font-size: 1.4rem;
  color: #2586d6;
  margin-right: 2rem;
}
.fc .fc-toolbar-title::before{
  content: 'Announces in ';
}

.p-datepicker-other-month{
  opacity: 0;
}

::-webkit-scrollbar { width: 15px; }
::-webkit-scrollbar-track { box-shadow: inset 0 0 5px rgb(252, 252, 252);  border-radius: 10px; }
::-webkit-scrollbar-thumb { background: #F1D49AAA; border-radius: 10px; }
::-webkit-scrollbar-thumb:hover { background: #F1D49AF0; }

